import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    campaigns: [],
    cars: [],
  },
  getters: {
    getCampaigns(state) {
      return state.campaigns
    },
    getCars(state) {
      return state.cars
    },
  },
  mutations: {
    SET_CAMPAIGNS(state, data) {
      state.campaigns = data
    },
    SET_CARS(state, data) {
      state.cars = data
    },
  },
  actions: {
    campaigns({ commit }) {
      axiosIns
        .post('/portal/campaigns')
        .then(response => {
          commit('SET_CAMPAIGNS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    cars({ commit }) {
      axiosIns
        .post('/portal/campaigns/cars')
        .then(response => {
          commit('SET_CARS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
