import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    sales: {
      daily: {
        count: 0,
        name: null,
        series: [],
      },
      weekly: {
        count: 0,
        name: null,
        series: [],
      },
      monthly: {
        count: 0,
        name: null,
        series: [],
      },
    },
    i2Sales: {
      daily: {
        count: 0,
        name: null,
        series: [],
      },
      weekly: {
        count: 0,
        name: null,
        series: [],
      },
      monthly: {
        count: 0,
        name: null,
        series: [],
      },
    },
    service: {
      daily: {
        count: 0,
        name: null,
        series: [],
      },
      weekly: {
        count: 0,
        name: null,
        series: [],
      },
      monthly: {
        count: 0,
        name: null,
        series: [],
      },
    },
    insurance: {
      daily: {
        count: 0,
        name: null,
        series: [],
      },
      weekly: {
        count: 0,
        name: null,
        series: [],
      },
      monthly: {
        count: 0,
        name: null,
        series: [],
      },
    },
  },
  getters: {
    getSales(state) {
      return state.sales
    },
    getI2Sales(state) {
      return state.i2Sales
    },
    getService(state) {
      return state.service
    },
    getInsurance(state) {
      return state.insurance
    },
  },
  mutations: {
    SET_SALES(state, data) {
      state.sales = data
    },
    SET_I2SALES(state, data) {
      state.i2Sales = data
    },
    SET_SERVICE(state, data) {
      state.service = data
    },
    SET_INSURANCE(state, data) {
      state.insurance = data
    },
  },
  actions: {
    counters({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/counters', data)
        .then(response => {
          commit('SET_SALES', response.data.sales)
          commit('SET_I2SALES', response.data.i2Sales)
          commit('SET_SERVICE', response.data.service)
          commit('SET_INSURANCE', response.data.insurance)
        })
        .catch(error => console.error(error))
    },
  },
}
