import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    contractedCompanies: [],
  },
  getters: {
    getContractedCompanies(state) {
      return state.contractedCompanies
    },
  },
  mutations: {
    SET_CONTRACTED_COMPANIES(state, data) {
      state.contractedCompanies = data
    },
  },
  actions: {
    contractedCompanies({ commit }) {
      const data = JSON.stringify({
        where: {
          'com_portal_contracted_companies.status': 1,
        },
      })
      axiosIns
        .post('/portal/contracted_companies', data)
        .then(response => {
          commit('SET_CONTRACTED_COMPANIES', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
