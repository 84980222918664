import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    gallery: [],
    photos: {},
  },
  getters: {
    getGallery(state) {
      return state.gallery
    },
    getPhotos(state) {
      return state.photos
    },
  },
  mutations: {
    SET_GALLERY(state, data) {
      state.gallery = data
    },
    SET_PHOTOS(state, data) {
      state.photos = data
    },
  },
  actions: {
    gallery({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/gallery', data)
        .then(response => {
          commit('SET_GALLERY', response.data.data)
        })
        .catch(error => console.error(error))
    },
    photos({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/gallery/getPhotos', data)
        .then(response => {
          commit('SET_PHOTOS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
