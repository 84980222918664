import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportage: {
      id: null,
      title: null,
      content: null,
      image: null,
    },
  },
  getters: {
    getReportage(state) {
      return state.reportage
    },
  },
  mutations: {
    SET_REPORTAGE(state, data) {
      state.reportage = data
    },
  },
  actions: {
    reportage({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/reportages', data)
        .then(response => {
          commit('SET_REPORTAGE', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
