import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    today: {},
  },
  getters: {
    getToday(state) {
      return state.today
    },
  },
  mutations: {
    SET_TODAY(state, data) {
      state.today = data
    },
  },
  actions: {
    today({ commit }) {
      axiosIns
        .post('/portal/quote_of_days/getToday')
        .then(response => {
          commit('SET_TODAY', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
