import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    organizations: [],
    companyOrganization: {
      id: null,
      title: null,
      subtitle: null,
      file: null,
    },
  },
  getters: {
    getOrganizations(state) {
      return state.organizations
    },
    companyOrganization(state) {
      return state.companyOrganization
    },
  },
  mutations: {
    SET_ORGANIZATIONS(state, data) {
      state.organizations = data
    },
    SET_COMPANY_ORGANIZATION(state, data) {
      state.companyOrganization = data
    },
  },
  actions: {
    organizations({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/organizations', data)
        .then(response => {
          commit('SET_ORGANIZATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getCompanyOrganization({ commit }) {
      axiosIns
        .post('/portal/organization_charts')
        .then(response => {
          commit('SET_COMPANY_ORGANIZATION', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
