import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    career: [],
  },
  getters: {
    getCareer(state) {
      return state.career
    },
  },
  mutations: {
    SET_CAREER(state, data) {
      state.career = data
    },
  },
  actions: {
    career({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/career', data)
        .then(response => {
          commit('SET_CAREER', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
