import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    birthdays: [],
  },
  getters: {
    getBirthdays(state) {
      return state.birthdays
    },
  },
  mutations: {
    SET_BIRTHDAYS(state, data) {
      state.birthdays = data
    },
  },
  actions: {
    birthdays({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/birthdays', data)
        .then(response => {
          commit('SET_BIRTHDAYS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
