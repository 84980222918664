import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    eduCalendars: [],
  },
  getters: {
    getEduCalendars(state) {
      return state.eduCalendars
    },
  },
  mutations: {
    SET_EDU_CALENDARS(state, data) {
      state.eduCalendars = data
    },
  },
  actions: {
    eduCalendars({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/edu_calendar', data)
        .then(response => {
          commit('SET_EDU_CALENDARS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
