import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    awards: [],
  },
  getters: {
    getAwards(state) {
      return state.awards
    },
  },
  mutations: {
    SET_AWARDS(state, data) {
      state.awards = data
    },
  },
  actions: {
    awards({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/awards', data)
        .then(response => {
          commit('SET_AWARDS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
