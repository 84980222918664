import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    newEmployeeEducations: [],
    isgEducations: [],
    kvkkEducations: [],
  },
  getters: {
    getNewEmployeeEducations(state) {
      return state.newEmployeeEducations
    },
    getIsgEducations(state) {
      return state.isgEducations
    },
    getKvkkEducations(state) {
      return state.kvkkEducations
    },
  },
  mutations: {
    SET_NEW_EMPLOYEES_EDUCATIONS(state, data) {
      state.newEmployeeEducations = data
    },
    SET_ISG_EDUCATIONS(state, data) {
      state.isgEducations = data
    },
    SET_KVKK_EDUCATIONS(state, data) {
      state.kvkkEducations = data
    },
  },
  actions: {
    newEmployeeEducations({ commit }) {
      const data = JSON.stringify({
        where: {
          'com_portal_fixed_educations.id_com_portal_fixed_education_types': 1,
        },
      })
      axiosIns
        .post('/portal/fixed_educations', data)
        .then(response => {
          commit('SET_NEW_EMPLOYEES_EDUCATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    isgEducations({ commit }) {
      const data = JSON.stringify({
        where: {
          'com_portal_fixed_educations.id_com_portal_fixed_education_types': 2,
        },
      })
      axiosIns
        .post('/portal/fixed_educations', data)
        .then(response => {
          commit('SET_ISG_EDUCATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    kvkkEducations({ commit }) {
      const data = JSON.stringify({
        where: {
          'com_portal_fixed_educations.id_com_portal_fixed_education_types': 3,
        },
      })
      axiosIns
        .post('/portal/fixed_educations', data)
        .then(response => {
          commit('SET_KVKK_EDUCATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
