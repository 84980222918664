import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import counters from './portal/counters'
import notices from './portal/notices'
import quoteOfDays from './portal/quote_of_days'
import poll from './portal/poll'
import campaigns from './portal/campaigns'
import insurances from './portal/insurances'
import meals from './portal/meals'
import events from './portal/events'
import awards from './portal/awards'
import birthdays from './portal/birthdays'
import bulletin from './portal/bulletin'
import career from './portal/career'
import gallery from './portal/gallery'
import reportage from './portal/reportage'
import newEmployees from './portal/new_employees'
import company from './portal/company'
import eduCalendar from './portal/edu_calendar'
import fixedEducations from './portal/fixed_educations'
import educations from './portal/educations'
import documents from './portal/documents'
import contractedCompanies from './portal/contracted_companies'
import organization from './portal/organization'
import meetingCalendars from './portal/meeting_calendars'
import meetingRooms from './portal/meeting_rooms'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    counters,
    notices,
    quoteOfDays,
    poll,
    campaigns,
    insurances,
    meals,
    events,
    awards,
    birthdays,
    bulletin,
    career,
    gallery,
    reportage,
    newEmployees,
    company,
    eduCalendar,
    fixedEducations,
    educations,
    documents,
    contractedCompanies,
    organization,
    meetingCalendars,
    meetingRooms,
  },
  strict: process.env.DEV,
})
