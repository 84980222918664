import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    getEvents(state) {
      return state.events
    },
  },
  mutations: {
    SET_EVENTS(state, data) {
      state.events = data
    },
  },
  actions: {
    events({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/events', data)
        .then(response => {
          commit('SET_EVENTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
