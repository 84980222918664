import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    history: [],
    press_releases: [],
    awards: [],
    quality_policy: {},
  },
  getters: {
    getHistory(state) {
      return state.history
    },
    getPressReleases(state) {
      return state.press_releases
    },
    getAwards(state) {
      return state.awards
    },
    getQualityPolicy(state) {
      return state.quality_policy
    },
  },
  mutations: {
    SET_HISTORY(state, data) {
      state.history = data
    },
    SET_PRESS_RELEASES(state, data) {
      state.press_releases = data
    },
    SET_AWARDS(state, data) {
      state.awards = data
    },
    SET_QUALITY_POLICY(state, data) {
      state.quality_policy = data
    },
  },
  actions: {
    history({ commit }) {
      axiosIns
        .post('/portal/company/history')
        .then(response => {
          commit('SET_HISTORY', response.data.data)
        })
        .catch(error => console.error(error))
    },
    press_releases({ commit }) {
      axiosIns
        .post('/portal/company/press_releases')
        .then(response => {
          commit('SET_PRESS_RELEASES', response.data.data)
        })
        .catch(error => console.error(error))
    },
    awards({ commit }) {
      axiosIns
        .post('/portal/company/awards')
        .then(response => {
          commit('SET_AWARDS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    qualityPolicy({ commit }) {
      axiosIns
        .post('/portal/company/quality_policy')
        .then(response => {
          commit('SET_QUALITY_POLICY', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
