import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    documents: [],
  },
  getters: {
    getDocuments(state) {
      return state.documents
    },
  },
  mutations: {
    SET_DOCUMENTS(state, data) {
      state.documents = data
    },
  },
  actions: {
    documents({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/documents', data)
        .then(response => {
          commit('SET_DOCUMENTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
