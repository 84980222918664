import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    bulletin: {
      id: null,
      title: null,
      content: null,
      image: null,
    },
  },
  getters: {
    getBulletin(state) {
      return state.bulletin
    },
  },
  mutations: {
    SET_BULLETIN(state, data) {
      state.bulletin = data
    },
  },
  actions: {
    bulletin({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/bulletins', data)
        .then(response => {
          commit('SET_BULLETIN', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
