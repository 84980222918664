import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    educations: [],
    switchboard: [],
  },
  getters: {
    getEducations(state) {
      return state.educations
    },
    getSwitchboard(state) {
      return state.switchboard
    },
  },
  mutations: {
    SET_EDUCATIONS(state, data) {
      state.educations = data
    },
    SET_SWITCHBOARD(state, data) {
      state.switchboard = data
    },
  },
  actions: {
    educations({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/educations', data)
        .then(response => {
          commit('SET_EDUCATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    switchboard({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/educations/switchboard', data)
        .then(response => {
          commit('SET_SWITCHBOARD', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
