import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    insurances: [],
  },
  getters: {
    getInsurances(state) {
      return state.insurances
    },
  },
  mutations: {
    SET_INSURANCES(state, data) {
      state.insurances = data
    },
  },
  actions: {
    insurances({ commit }) {
      axiosIns
        .post('/portal/insurances')
        .then(response => {
          commit('SET_INSURANCES', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
