import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    newEmployees: [],
  },
  getters: {
    getNewEmployees(state) {
      return state.newEmployees
    },
  },
  mutations: {
    SET_NEW_EMPLOYEES(state, data) {
      state.newEmployees = data
    },
  },
  actions: {
    newEmployees({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/new_employees', data)
        .then(response => {
          commit('SET_NEW_EMPLOYEES', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
