import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    poll: {
      id: null,
      question: null,
      options: [],
      voted: 0,
    },
    pollResult: {
      id: null,
      question: null,
      options: [],
    },
  },
  getters: {
    getPoll(state) {
      return state.poll
    },
    getPollResult(state) {
      return state.pollResult
    },
  },
  mutations: {
    SET_POLL(state, data) {
      state.poll = data
    },
    SET_POLL_RESULT(state, data) {
      state.pollResult = data
    },
    SET_VOTE(state, data) {
      state.poll.voted = data
    },
  },
  actions: {
    poll({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/polls/getPoll', data)
        .then(response => {
          commit('SET_POLL', response.data.data)
          commit('SET_POLL_RESULT', response.data.result)
        })
        .catch(error => console.error(error))
    },
    pollResult({ commit }, id) {
      const data = JSON.stringify({ id_com_portal_polls: id })
      axiosIns
        .post('/portal/polls/getPollResult', data)
        .then(response => {
          commit('SET_POLL_RESULT', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pollVote({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/polls/vote', data)
        .then(response => {
          commit('SET_VOTE', response.data.data)
          commit('SET_POLL_RESULT', response.data.result)
        })
        .catch(error => console.error(error))
    },
  },
}
